import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomeTabPage.vue'),
        
      },
      {
        path: 'leads',
        component: () => import('@/views/LeadTabPage.vue'),
        
      },
      {
        path: 'agenda',
        component: () => import('@/views/AgendaTabPage.vue'),
        
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfileTabPage.vue')
      }
    ]
  },
  {
    path: '/logout',
    name:'LogOut',
    component: () => import('@/views/HomeTabPage.vue'),
    beforeEnter() {
      location.href = process.env.VUE_APP_SSO_URL+'/realms/poc_uys/protocol/openid-connect/logout?post_logout_redirect_uri='+process.env.VUE_APP_URL +"&id_token_hint="+ store.state.auth.idToken
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
