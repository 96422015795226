import authModule from './modules/auth';
import filtersModule from './modules/filters';
import searchModule from './modules/search';
import TodosModule from './modules/todos';

import {createStore} from 'vuex';
 
const store = createStore({
    modules:{
        auth: authModule,
        filters: filtersModule,
        search : searchModule,
        todos : TodosModule
    }
});
 
export default store;