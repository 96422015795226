import GenericDataService from "../../services/GenericDataService";
import {loadLanguageAsync} from '@/i18n'
import { Preferences } from '@capacitor/preferences';


const state = () => ({
    idToken:"",
    accessToken:"",
    user: {}, // currentUser OU loggedAs
    currentUser: {}, // Toujours le user principal
    loggedAs: null, // Toujour le user en tant que
    currentAccountId: 0,
    avatarKey: 0,
    currentAccountId: 0,
    loader: false
});
 
const getters = {};
 
const actions = {
    async GET_CURRENT_USER({commit}) {
        await GenericDataService.getData("myprofile/get").then(async (response) => {
            const { value } = await Preferences.get({ key: 'account_id' });
            if(value && value != 0){
                commit("setCurrentAccountId", value) 
            }

            commit("setCurrentUser", response);
        })
    },
    SET_AUTH({commit}, payload){
        commit('setAuth', payload)
    },
    SET_CURRENT_ACCOUNT_ID({ commit }, value){
        commit("setCurrentAccountId", value)
    },
    SET_LOADER({commit}, value){
        commit("setLoader", value)
    }
};
 
const mutations = {
    setCurrentAccountId(state, accountId){
        state.currentAccountId = accountId
    },
    async setAuth(state, payload){
        state.idToken = payload.idToken
        state.accessToken = payload.accessToken
    },
    async setCurrentUser(state, currentUser){
        state.currentUser = currentUser.data.data
        
        if(state.currentAccountId == 0 || !state.currentAccountId)
            state.currentAccountId = ""+currentUser.data.data.accountParameters.id
        
        
        document.title = state.currentUser.accountParameters.name
        state.loggedAs = currentUser.data.data.loggedAs
        state.user = currentUser.data.data.loggedAs ? state.loggedAs : state.currentUser.profile;
        if(currentUser.data.data.parameters.lang){
           loadLanguageAsync(currentUser.data.data.parameters.lang)
        }
    },
    setLoader(state, value){
        state.loader = value
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}