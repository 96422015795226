/* eslint-disable no-console */

import { register } from 'register-service-worker';
import axiosHttp from '@/http-common';

// Envoie les clés web-push au serveur
function saveSubscription(subscription) {
    axiosHttp.http.post('/user/webPushSubscribe', subscription);
}

// Encode la clé public
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function registerWebPush() {
   if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'preprod') {
    register(`${process.env.BASE_URL}sw.js`, {
        ready() {
            console.log('ready');
        },
        async registered() {
            console.log('Service worker has been registered.');
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                let registration = await navigator.serviceWorker.getRegistration();
                registration.update();
                let subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    // VAPID key
                    applicationServerKey: urlBase64ToUint8Array("BKktQtRSMtnnBjbLuWOqCLU_WsA0G7xYq-kFoxTazOPz39A8z9KAah9fXACuwOu8Am2Y45aOPtdx-JsXZ7i63p4")
                });
                saveSubscription(subscription);
            }
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated() {
            console.log('New content is available; please refresh.');
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
 }
}