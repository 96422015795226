import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import store from './store';
import {i18n} from './i18n'
import VueVirtualScroller from 'vue-virtual-scroller';
import {registerWebPush} from './registerServiceWorker.js';
import {func} from './functions'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { IonAvatar, IonSpinner, IonList, IonPopover, IonicVue, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonFab, IonModal, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonText, IonImg, IonThumbnail, IonFabButton, IonChip, IonLabel, IonItem, IonToggle } from '@ionic/vue';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText  } from '@fortawesome/vue-fontawesome'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'

import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';

/* add icons to the library */
library.add(fad, fal, fat, fas)


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Theme CSS */
import './theme/global.scss';

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios'
  })
  .use(store)
  .use(router)
  .use(VueVirtualScroller)
  .use(i18n)
  .component('ion-content', IonContent)
  .component('ion-header', IonHeader)
  .component('ion-toolbar', IonToolbar)
  .component('ion-title', IonTitle)
  .component('ion-page', IonPage)
  .component('ion-buttons', IonButtons)
  .component('ion-button', IonButton)
  .component('ion-fab', IonFab)
  .component('ion-fab-button', IonFabButton)
  .component('ion-modal', IonModal)
  .component('ion-grid', IonGrid)
  .component('ion-row', IonRow)
  .component('ion-col', IonCol)
  .component('ion-card', IonCard)
  .component('ion-card-header', IonCardHeader)
  .component('ion-card-subtitle', IonCardSubtitle)
  .component('ion-card-title', IonCardTitle)
  .component('ion-card-content', IonCardContent)
  .component('ion-text', IonText)
  .component('ion-img', IonImg)
  .component('ion-chip', IonChip)
  .component('ion-thumbnail', IonThumbnail)
  .component('ion-label', IonLabel)
  .component('ion-item', IonItem)
  .component('ion-list', IonList)
  .component('ion-avatar', IonAvatar)
  .component('ion-popover', IonPopover)
  .component('ion-spinner', IonSpinner)
  .component('ion-toggle', IonToggle)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('font-awesome-layers', FontAwesomeLayers)
  .component('font-awesome-layer-text', FontAwesomeLayersText)
  .use(VueKeyCloak, {
    init: {
      onLoad: 'login-required',
      realm: process.env.VUE_APP_SSO_REALM,
      url: process.env.VUE_APP_SSO_URL,
      clientId: process.env.VUE_APP_SSO_CLIENT
    },
    config: {
      realm: process.env.VUE_APP_SSO_REALM,
      url: process.env.VUE_APP_SSO_URL,
      clientId: process.env.VUE_APP_SSO_CLIENT 
    },
    logout: {
      redirectUri: 'com.lexxy.app://'
    },
    onReady: async (keycloak) => {
      const info = await Device.getInfo();

      if(info.platform !== 'web')
        StatusBar.setStyle({ style: Style.Dark });
      
      router.isReady().then(() => {
        const payload = {
          idToken: keycloak.idToken,
          accessToken: keycloak.token
        }
        if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
          store.dispatch("auth/SET_AUTH", payload).then(() => {
            store.dispatch("auth/GET_CURRENT_USER").then(() => {
              app.mount('#app');
              registerWebPush();
            })
          });
        }

        //gestion refresh du token
        setInterval(() => {
          keycloak.updateToken(110).then((refreshed) => {
            if (refreshed) {
              // console.error(keycloak.token)
              const payload = {
                idToken: keycloak.idToken,
                accessToken: keycloak.token
              }
              store.commit("auth/setAuth", payload);
            } else {
              //console.log('Token not refreshed, valid for '
               // + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
          }).catch(() => {
            //Vue.$log.error('Failed to refresh token');
          });
        }, 6000)
      });
    }
  });

  app.config.globalProperties.$func = func

  
  