
import axiosHttp from '@/http-common'
import router from '../router'
import store from '../store'
import { isNavigationFailure, NavigationFailureType } from '../router'


class GenericDataService {
  
  getObject(ref) {
    return axiosHttp.http.get("/test/get/?name="+ref);
  }
  getList(number) {
    return axiosHttp.http.get("/test/get/?name=liste"+number)
  }
  postData(url, payload) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.post(url, JSON.stringify(payload) ).then((response) => {
        let clone = JSON.parse(JSON.stringify(response))
        console.log('responseCloneAxios', clone)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
  getData(url, config = false) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.get(url, config).then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    }) 
  }
  setData(url, data) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.post(url, data).then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log('ServiceError', error.response)
        let payload = error.response.data
        store.dispatch('ADD_ERROR', payload)
        // if(router.currentRoute.fullPath != '/nico-test'){
        //   router.push({name: 'Error404'}).catch(() => {});
        // }
        reject(error)
      })
    }) 
  }
}

export default new GenericDataService();
