import { createI18n } from 'vue-i18n'
import axios from 'axios'
import axiosHttp from '@/http-common'

const loadedLanguages = []

export const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLanguageAsync('fr'),
  missing: (locale, key, vm, values) => {
    let payload = {
      key: key,
      lang: locale,
      url: document.location.pathname
    }
    if(key && key != ''){
      axiosHttp.http.post(`/ns/i18n/addNewKey`, payload).then(response => {
        console.log("trad_manquante", response)
      })
    }
   
    return key
  },
})


function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang 
  document.querySelector('html').setAttribute('lang', lang)
  //store.dispatch('settings/SET_PARAM', {key: 'lang', data: `${lang}`}).then((e) => {
    return lang
  //})
}

// export function test($event){
//   console.log("missing", $event)
// }

export function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  setTimeout(() => {    
     axiosHttp.http.get(`/ns/i18n/get/${lang}`).then(response => {
        let msgs = response.data.data
        loadedLanguages.push(lang)
        i18n.global.setLocaleMessage(lang, msgs)
        setI18nLanguage(lang)
        Promise.resolve()
    })
  });
    
}


// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
