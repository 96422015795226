<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar color="deep_primary" slot="bottom">
        <ion-tab-button tab="tab1"  href="/tabs/home">
          <font-awesome-icon icon="fa-solid fa-house" class="tabIcon"/>
          <ion-label class="labelHome">{{$t('homepage')}}</ion-label>
        </ion-tab-button>
        <ion-tab-button :disabled="operationFilters.length > 0 ? false : true" tab="tab2" href="/tabs/leads">
          <font-awesome-icon icon="fa-solid fa-users" class="tabIcon"/>
          <ion-label class="labelHome">{{$t('leads')}}</ion-label>
        </ion-tab-button>
        
        <ion-tab-button :disabled="operationFilters.length > 0 ? false : true" tab="tab3" href="/tabs/agenda">
          <font-awesome-icon icon="fa-solid fa-calendar" class="tabIcon"/>
          <ion-label class="labelHome">{{$t('agenda')}}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab4" href="/tabs/profile">
          <font-awesome-icon icon="fa-solid fa-circle-user" class="tabIcon" />
          <ion-label class="labelHome">{{$t('myprofile')}}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, onIonViewDidEnter } from '@ionic/vue';
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TabsPage',
  components: { IonTabs, IonTabBar, IonTabButton, IonRouterOutlet },
  setup(props) {
    const store = useStore()

    const operationFilters = ref(false);

    const filters = computed(() => {
        return store.state.filters.filters
    });
    watch(filters, (currentValue, oldValue) => {
      operationFilters.value = store.getters['filters/getOperationValue']
    }); 

    onIonViewDidEnter(() => {
      setTimeout(() => {
        operationFilters.value = store.getters['filters/getOperationValue']
      }, 200);
    })
    
    return {
      operationFilters
    }
  }
});
</script>

<style lang="scss">
  ion-tab-bar {
    height:70px;
    --border: none;
    box-shadow: rgb(0 0 0 / 12%) 0px -3px 4px;
  }
  ion-tab-button {
    color: #FFF !important;
    padding:5px !important;
  }
  .tab-selected {
      color: #FFF !important;
      background-color: var(--ion-color-tertiary) !important;
  }
  .tabIcon {
    font-size:25px;
    margin-bottom:5px;
  }
  .labelHome {
    font-size:11px;
  }
</style>