const state = () => ({
    todos: [],
    reloadTodos: 0,
});
 
const getters = {};
 
const actions = {
    SET_TODOS({ commit }, value){
        commit("setTodos", value)
    },
    SET_RELOAD_TODOS({commit}){
        commit("setReloadTodos")
    }
};
 
const mutations = {
    setTodos(state, todos){
        state.todos = todos
    },
    setReloadTodos(state){
        state.reloadTodos++;
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}