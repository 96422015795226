import axios from "axios";
import store from './store';
import router from './router'

//console.log(store);
class axiosHttp {
  constructor () {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Content-type": "application/json"
      }
    })

    this.http.interceptors.request.use(config => {
      config.headers.Token = store.state.auth.accessToken
      config.headers.APPMOBILE = true
      if(store.state.auth.currentAccountId != 0){
        config.headers.ACCOUNTID = store.state.auth.currentAccountId
      }
      
      return config
    })
  }
}
export default new axiosHttp();