


const state = () => ({
    searchContact: null
});
 
const getters = {
    getSearchContact(state){
        let payload = { filters:{} }
        if(state.searchContact && state.searchContact != '')
           payload.filters.search = state.searchContact
        
        return payload
    }
};
 
const actions = {
    SET_SEARCH_CONTACT({ commit }, value){
        commit("setSearchContact", value)
    }
};
 
const mutations = {
    setSearchContact(state, value){
        state.searchContact = value
    }    
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}